import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackendApi } from '../lib/backend';
import {
  BillableCodeUpdateReq,
  EpisodeOpenDto,
  EpisodeUpdateDto,
} from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';

export function useBillableCodes() {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['billableCodes'], {
    queryFn: () =>
      api.episodeControllerGetAllBillableCodes().then(unwrapResponse),
    enabled: !!idToken.data,
  });
}

export function useEpisodes() {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['episodes'], {
    queryFn: () => api.episodeControllerGetEpisodes().then(unwrapResponse),
    enabled: !!idToken.data,
  });
}

export function useUpdateEpisode() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async ({
      episodeId,
      body,
    }: {
      episodeId: string;
      body: EpisodeUpdateDto;
    }) => {
      const response = await api.episodeControllerUpdateEpisode(
        episodeId,
        body,
      );
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('episodes');
        queryClient.invalidateQueries('billableCodes');
      },
    },
  );
}

export function useStartNewEpisode() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: EpisodeOpenDto) => {
      const response = await api.episodeControllerStartNewEpisode(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('episodes');
        queryClient.invalidateQueries('billableCodes');
      },
    },
  );
}

export function useUpdateBillableCode() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async ({
      episodeId,
      billableCodeId,
      body,
    }: {
      episodeId: string;
      billableCodeId: string;
      body: BillableCodeUpdateReq;
    }) => {
      const response = await api.episodeControllerUpdateBillableCode(
        episodeId,
        billableCodeId,
        body,
      );
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('episodes');
        queryClient.invalidateQueries('billableCodes');
      },
    },
  );
}

export function useDownloadCPTCodeReport(episodeId: string, codeId: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['billableCodes', episodeId, codeId], {
    queryFn: () =>
      api
        .episodeControllerDownloadReport(episodeId, codeId)
        .then(unwrapResponse),
    enabled: !!idToken.data,
  });
}
