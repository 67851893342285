import { useQuery } from 'react-query';

async function naiveFetchJSON(url: string) {
  const response = await fetch(url);

  if (response.status >= 400) {
    throw new Error(`Invalid http response: ${response.statusText}`);
  }

  return await response.json();
}

export function getPoseClassificationTree(group: string) {
  return naiveFetchJSON(`/classifier/PoseClassificationScikit_${group}.json`);
}

export function getGeneralTestClassifier(model: string) {
  return naiveFetchJSON(`/general-test-classifier/${model}.json`);
}

// TODO: rename this file some variant of "local data"
function getLottieAnimation(path: string) {
  return naiveFetchJSON(`/lottie/${path}.json`);
}

export function useLottieAnimation(path: string | null) {
  return useQuery(['lottie', path], {
    queryFn: () => (path ? getLottieAnimation(path) : null),
    enabled: !!path,
  });
}

export async function getLottieAnimationLength(path: string | null) {
  if (!path) {
    return null;
  }
  const { ip, op, fr } = await getLottieAnimation(path);
  return (op - ip) / fr;
}
