import { useEffect, useMemo } from 'react';
import {
  ExerciseStatsSource,
  StatsSubmission,
  useUpdateExerciseStat,
} from '../../../api/exercise-stats';
import { SessionState } from '../web-coach';
import { useWhoAmI } from '../../../api/clinician';
import { uuidv4 } from '@firebase/util';
import { usePublish, useSubscribe } from '../../../lib/stateful-queue';

/*
There exists a queue of data (stats) being produced
It can only be consumed when some conditions are met (logged in)
*/

export function useStatsStorage(
  sessionState: SessionState | undefined,
  prescriptionId: string | undefined,
  source: ExerciseStatsSource,
) {
  const publishStats = usePublish<StatsSubmission>('stats');
  const whoAmIQ = useWhoAmI();
  const patient = whoAmIQ.data?.patient;
  const session_id = useMemo(() => uuidv4(), []);
  const statsLength = sessionState?.stats?.length;

  useEffect(() => {
    if (!prescriptionId) {
      return;
    }

    const stat = sessionState?.stats?.[(sessionState.stats?.length ?? 1) - 1];
    if (!stat) {
      return;
    }

    const submission: StatsSubmission = {
      patientId: patient?.id ?? '',
      data: {
        id: stat.id || uuidv4(),
        prescription_id: prescriptionId,
        session_id,
        source,
        ...stat,
      },
    };

    publishStats(submission);
  }, [statsLength]);
}

export function useDeferredStatsSubmission() {
  const whoAmIQ = useWhoAmI();
  const patientId = whoAmIQ.data?.patient?.id;
  const updateExerciseStatQ = useUpdateExerciseStat();

  const eventName = patientId ? 'stats' : undefined;

  useSubscribe<StatsSubmission>(eventName, (submission) => {
    if (!patientId) {
      throw new Error('Unexpected missing patient id');
    }
    return updateExerciseStatQ.mutateAsync({
      ...submission,
      patientId,
    });
  });
}
