import { PrescriptionDto } from '../../api/backend-client/data-contracts';

export const SELF_TEST_PRESCRIPTION: PrescriptionDto = {
  id: 'SELF_TEST_PRESCRIPTION',
  title: 'Self Test Prescription',
  configurable_exercise: [
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'EX-00033',
      checkInitialPose: true,
      initialPosePrompt: 'Face forward with your feet together',
      completion: 'anyRep',
    },
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'EX-00038',
      checkInitialPose: true,
      initialPosePrompt: 'Face toward the side',
      completion: 'anyRep',
    },
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'EX-00060',
      checkInitialPose: true,
      initialPosePrompt: 'Face toward the side',
      completion: 'anyRep',
    },
  ],
  create_clinician_id: '',
  create_clinician_email: '',
  last_modified_clinician_id: '',
  last_modified_clinician_email: '',
  is_active: false,
};
