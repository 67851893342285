// import { encode } from '@msgpack/msgpack';
import { useQuery, UseQueryResult } from 'react-query';
import { useBackendApi } from '../lib/backend';
import { Api } from './backend-client/Api';
import { ExerciseDto } from './backend-client/data-contracts';
import { unwrapResponse, useQueryMemoMap } from './util';

async function getThenPackExerciseNetworkData(api: Api) {
  const [oldExercises, oldFlexibilities, scores, frequencies] = await Promise.all([
    api.exerciseControllerGetExercises().then(unwrapResponse),
    api.exerciseControllerGetExerciseFlexibilities().then(unwrapResponse),
    api.exerciseControllerGetExerciseScores().then(unwrapResponse),
    api.frequencyControllerListExerciseFrequency().then(unwrapResponse),
  ]);

  const exercises = oldExercises.map((oldExercise) => {
    if (oldExercise.id == 'EX-00034') {
      return {
        ...oldExercise,
        angles_of_interest: [
          {
            base_ROM_angle: 0,
            kps_angle_goals: [
              {
                z_directions: null,
                ref_point: null,
                side: 'left',
                method: 'twoLineXY',
                second_start: ['SACRUM', 'SACRUM'],
                second_end: ['SACRUM', 'STERNUM'],
                first_start: ['SACRUM', 'SACRUM'],
                first_end: ['STERNUM', 'STERNUM'],
              },
            ],
            is_individual: false,
            is_highlighted: true,
            is_value_visible: true,
            state_id: 'side_bend_engaged_left_state',
            is_ROM_flipped: false,
          },
          {
            base_ROM_angle: 0,
            kps_angle_goals: [
              {
                z_directions: null,
                ref_point: null,
                side: 'right',
                method: 'twoLineXY',
                second_start: ['SACRUM', 'SACRUM'],
                second_end: ['SACRUM', 'STERNUM'],
                first_start: ['SACRUM', 'SACRUM'],
                first_end: ['STERNUM', 'STERNUM'],
              },
            ],
            is_individual: false,
            is_highlighted: true,
            is_value_visible: true,
            state_id: 'side_bend_engaged_right_state',
            is_ROM_flipped: false,
          },
        ],
      };
    } else if (oldExercise.id == 'EX-00111') {
      return {
        ...oldExercise,
        angles_of_interest: [
          {
            base_ROM_angle: 0,
            kps_angle_goals: [
              {
                z_directions: null,
                ref_point: null,
                side: 'left',
                method: 'twoLineXY',
                second_start: ['SACRUM', 'SACRUM'],
                second_end: ['SACRUM', 'STERNUM'],
                first_start: ['SACRUM', 'SACRUM'],
                first_end: ['STERNUM', 'STERNUM'],
              },
            ],
            is_individual: false,
            is_highlighted: true,
            is_value_visible: true,
            state_id: 'standing_back_extension_engaged_left_state',
            is_ROM_flipped: false,
          },
          {
            base_ROM_angle: 0,
            kps_angle_goals: [
              {
                z_directions: null,
                ref_point: null,
                side: 'right',
                method: 'twoLineXY',
                second_start: ['SACRUM', 'SACRUM'],
                second_end: ['SACRUM', 'STERNUM'],
                first_start: ['SACRUM', 'SACRUM'],
                first_end: ['STERNUM', 'STERNUM'],
              },
            ],
            is_individual: false,
            is_highlighted: true,
            is_value_visible: true,
            state_id: 'standing_back_extension_engaged_right_state',
            is_ROM_flipped: false,
          },
        ],
      };
    } else if (oldExercise.id == 'EX-00028') {
      return {
        ...oldExercise,
        angles_of_interest: [
          {
            base_ROM_angle: 0,
            kps_angle_goals: [
              {
                z_directions: null,
                ref_point: null,
                side: 'left',
                method: 'twoLineXY',
                second_start: ['SACRUM', 'SACRUM'],
                second_end: ['SACRUM', 'TOP_BORDER'],
                first_start: ['SACRUM', 'SACRUM'],
                first_end: ['STERNUM', 'STERNUM'],
              },
            ],
            is_individual: false,
            is_highlighted: true,
            is_value_visible: true,
            state_id: 'forward_bend_complete_left_state',
            is_ROM_flipped: false,
          },
          {
            base_ROM_angle: 0,
            kps_angle_goals: [
              {
                z_directions: null,
                ref_point: null,
                side: 'right',
                method: 'twoLineXY',
                second_start: ['SACRUM', 'SACRUM'],
                second_end: ['SACRUM', 'TOP_BORDER'],
                first_start: ['SACRUM', 'SACRUM'],
                first_end: ['STERNUM', 'STERNUM'],
              },
            ],
            is_individual: false,
            is_highlighted: true,
            is_value_visible: true,
            state_id: 'forward_bend_complete_right_state',
            is_ROM_flipped: false,
          },
        ],
      };
    }
    return oldExercise;

  });

  const flexibilities = oldFlexibilities.map((oldFlexibility) => {
    if (oldFlexibility.exercise_id == 'EX-00034' || oldFlexibility.exercise_id == 'EX-00111') {
      return {
        ...oldFlexibility,
        goals: [
          {
            acceptable_range: {
              max: 45,
              min: 15
            },
            ideal_range: {
              max: 45,
              min: 15
            },
            setting: "moderate"
          },
          {
            acceptable_range: {
              max: 45,
              min: 15
            },
            ideal_range: {
              max: 45,
              min: 15
            },
            setting: "flexible"
          },
          {
            acceptable_range: {
              max: 45,
              min: 15
            },
            ideal_range: {
              max: 45,
              min: 15
            },
            setting: "limited"
          },
        ],
      };

    } else if (oldFlexibility.exercise_id == 'EX-00028') {
      return {
        ...oldFlexibility,
        goals: [
          {
            acceptable_range: {
              max: 140,
              min: 70
            },
            ideal_range: {
              max: 140,
              min: 70
            },
            setting: "moderate"
          },
          {
            acceptable_range: {
              max: 140,
              min: 70
            },
            ideal_range: {
              max: 140,
              min: 70
            },
            setting: "flexible"
          },
          {
            acceptable_range: {
              max: 140,
              min: 70
            },
            ideal_range: {
              max: 140,
              min: 70
            },
            setting: "limited"
          },
        ],
      };

    }

    return oldFlexibility;

  });

  const networkData = { exercises, flexibilities, scores, frequencies };

  //return encode(networkData);
  return JSON.stringify(networkData);
}

export function usePackedExerciseNetworkData() {
  const api = useBackendApi();

  return useQuery(['exercise', 'data'], {
    queryFn: () => getThenPackExerciseNetworkData(api),
  });
}

export function useExerciseList() {
  const api = useBackendApi();

  return useQuery(['exercise', 'list'], {
    queryFn: () => api.exerciseControllerGetExercises().then(unwrapResponse),
  });
}

export function useExerciseFlexibilities() {
  const api = useBackendApi();

  return useQuery(['exercise'], {
    queryFn: () =>
      api.exerciseControllerGetExerciseFlexibilities().then(unwrapResponse),
  });
}

export function useExerciseMap(): UseQueryResult<Record<string, ExerciseDto>> {
  const exerciseQ = useExerciseList();

  return useQueryMemoMap(exerciseQ, (exercises) =>
    exercises.reduce((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {} as Record<string, ExerciseDto>),
  );
}

export function useExerciseMaxRomDistribution(exerciseId: string) {
  const api = useBackendApi();

  return useQuery(['exercise', exerciseId], {
    queryFn: () =>
      api
        .exerciseControllerGetExerciseMaxRomDistribution(exerciseId)
        .then(unwrapResponse),
  });
}

export function useExerciseMaxRomDistributions(exerciseIds: string[]) {
  const api = useBackendApi();

  return useQuery(['exercise', exerciseIds], {
    queryFn: () =>
      api
        .exerciseControllerGetExerciseMaxRomDistributions({
          exercise_ids: exerciseIds,
        })
        .then(unwrapResponse),
  });
}
