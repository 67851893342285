/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountResponse,
  ActivityReportDto,
  AnonymousOpenTokConnectionDTO,
  AppointmentDto,
  AppointmentReportCsv,
  AppointmentReportDto,
  AppointmentRomReportDto,
  AppointmentScreenshotDto,
  AppointmentTokenUrl,
  AssessmentGroupDto,
  AthenaTokenResponse,
  BillableCodeUpdateReq,
  CalendlyAction,
  CapabilityReport,
  CheckConsentDto,
  ClinicianDto,
  ConsentDto,
  ConsentRequestDto,
  ContactDto,
  CreateAppointmentDto,
  CreateConnectionDto,
  CreateContactDto,
  CreateMessageDto,
  CreateTriageLogDto,
  DownloadReportDto,
  EmailActivityDto,
  EmailDto,
  EpisodeDto,
  EpisodeOpenDto,
  EpisodeUpdateDto,
  EpisodeWithBillableCodeDto,
  ExerciseDto,
  ExerciseFlexibilityDto,
  ExerciseJointOfInterestDto,
  ExerciseJointTraceDto,
  ExerciseKeypoints,
  ExerciseMaxRomDistributionDto,
  ExerciseScoreDto,
  ExerciseScreenshotDto,
  ExerciseStats,
  FormDto,
  FrequencyDateDto,
  FrequencyDto,
  GeneralTestDto,
  HEPReportDto,
  KeypointSnapshotDto,
  LoginReq,
  MessageDto,
  NoteDto,
  OpenTokConnectionDto,
  PatientDto,
  PatientEpisodesDto,
  PrescriptionDto,
  PrescriptionTemplateDto,
  PurchaseDto,
  ReceiptDto,
  ReportScreenshotDto,
  RtmPatientsUpdateReq,
  SaveSelfTestStatsDto,
  ScreenshotItem,
  ScreenshotUploadDto,
  ScreenshotUrlDto,
  SearchSummaryDto,
  SessionExerciseStats,
  SessionSummaryDto,
  TeamDto,
  UserNotificationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerHealthCheck
   * @request GET:/api/healthz
   */
  appControllerHealthCheck = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/healthz`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppControllerGetRoom
   * @request POST:/api/room/{roomId}
   */
  appControllerGetRoom = (roomId: string, params: RequestParams = {}) =>
    this.request<OpenTokConnectionDto, any>({
      path: `/api/room/${roomId}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AccountControllerGetWhoami
   * @request GET:/api/whoami
   * @secure
   */
  accountControllerGetWhoami = (params: RequestParams = {}) =>
    this.request<AccountResponse, any>({
      path: `/api/whoami`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AccountControllerUpdateNotifications
   * @request POST:/api/account/{uid}/notification
   * @secure
   */
  accountControllerUpdateNotifications = (uid: string, data: UserNotificationDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/account/${uid}/notification`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ClinicianControllerGetMyPatients
   * @request GET:/api/clinician/patients
   * @secure
   */
  clinicianControllerGetMyPatients = (params: RequestParams = {}) =>
    this.request<PatientDto[], any>({
      path: `/api/clinician/patients`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ClinicianControllerGetClinician
   * @request GET:/api/clinician/{clinicianId}
   * @secure
   */
  clinicianControllerGetClinician = (clinicianId: string, params: RequestParams = {}) =>
    this.request<ClinicianDto, any>({
      path: `/api/clinician/${clinicianId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ClinicianControllerGetClinicians
   * @request GET:/api/clinicians
   * @secure
   */
  clinicianControllerGetClinicians = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ClinicianDto[], any>({
      path: `/api/clinicians`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ClinicianControllerGetBecomeClinician
   * @request POST:/api/becomeClinician
   * @secure
   */
  clinicianControllerGetBecomeClinician = (params: RequestParams = {}) =>
    this.request<ClinicianDto, any>({
      path: `/api/becomeClinician`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ClinicianControllerUpdateClinician
   * @request POST:/api/clinician
   * @secure
   */
  clinicianControllerUpdateClinician = (data: ClinicianDto, params: RequestParams = {}) =>
    this.request<ClinicianDto, any>({
      path: `/api/clinician`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerLogin
   * @request POST:/api/patient/login
   * @secure
   */
  patientControllerLogin = (data: LoginReq, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/patient/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerGetPatient
   * @request GET:/api/patient/{patientId}
   * @secure
   */
  patientControllerGetPatient = (patientId: string, params: RequestParams = {}) =>
    this.request<PatientDto, any>({
      path: `/api/patient/${patientId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerGetEmailActivity
   * @request GET:/api/emailActivity/{patientId}/clinician/{clinicianId}
   * @secure
   */
  patientControllerGetEmailActivity = (patientId: string, clinicianId: string, params: RequestParams = {}) =>
    this.request<EmailActivityDto[], any>({
      path: `/api/emailActivity/${patientId}/clinician/${clinicianId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerGetAllPatientsByTeamIds
   * @request GET:/api/patients/all
   * @secure
   */
  patientControllerGetAllPatientsByTeamIds = (
    query: {
      teamIds: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PatientDto[], any>({
      path: `/api/patients/all`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerGetPatientsForTeams
   * @request GET:/api/patient
   * @secure
   */
  patientControllerGetPatientsForTeams = (
    query: {
      teamIds: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PatientDto[], any>({
      path: `/api/patient`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerUpdatePatient
   * @request POST:/api/patient
   * @secure
   */
  patientControllerUpdatePatient = (data: PatientDto, params: RequestParams = {}) =>
    this.request<PatientDto, any>({
      path: `/api/patient`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerGetPatients
   * @request GET:/api/patients
   * @secure
   */
  patientControllerGetPatients = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PatientDto[], any>({
      path: `/api/patients`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerUpdateRtmPatients
   * @request POST:/api/patients/rtm
   * @secure
   */
  patientControllerUpdateRtmPatients = (data: RtmPatientsUpdateReq, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/patients/rtm`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name PatientControllerCreatePatient
   * @request POST:/api/patient/becomePatient
   * @secure
   */
  patientControllerCreatePatient = (params: RequestParams = {}) =>
    this.request<PatientDto, any>({
      path: `/api/patient/becomePatient`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerCreateAppointment
   * @request POST:/api/appointment
   * @secure
   */
  appointmentControllerCreateAppointment = (data: CreateAppointmentDto, params: RequestParams = {}) =>
    this.request<AppointmentDto, any>({
      path: `/api/appointment`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerListAppointments
   * @request GET:/api/appointment
   * @secure
   */
  appointmentControllerListAppointments = (
    query?: {
      patient?: string;
      clinician?: string;
      attendee?: string;
      ascBy?: string;
      decBy?: string;
      limit?: string;
      startAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AppointmentDto[], any>({
      path: `/api/appointment`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerRunCreateAppointmentWorkflow
   * @request POST:/api/appointment/creation-workflow
   * @secure
   */
  appointmentControllerRunCreateAppointmentWorkflow = (data: CreateAppointmentDto, params: RequestParams = {}) =>
    this.request<AppointmentDto, any>({
      path: `/api/appointment/creation-workflow`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerSendAppointmentEmail
   * @request POST:/api/appointment/email
   * @secure
   */
  appointmentControllerSendAppointmentEmail = (data: AppointmentDto, params: RequestParams = {}) =>
    this.request<EmailDto, any>({
      path: `/api/appointment/email`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerListClinicianAppointments
   * @request GET:/api/appointment/clinician/{clinicianId}
   * @secure
   */
  appointmentControllerListClinicianAppointments = (clinicianId: string, params: RequestParams = {}) =>
    this.request<AppointmentDto[], any>({
      path: `/api/appointment/clinician/${clinicianId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerGetAppointments
   * @request GET:/api/appointments
   * @secure
   */
  appointmentControllerGetAppointments = (
    query: {
      appointmentIds: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<AppointmentDto[], any>({
      path: `/api/appointments`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerGetPatientsInClinicianAppointments
   * @request GET:/api/appointment/patients
   * @secure
   */
  appointmentControllerGetPatientsInClinicianAppointments = (
    query: {
      clinicianId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<PatientDto[], any>({
      path: `/api/appointment/patients`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerCheckConsentAndCreateConnectionWithToken
   * @request POST:/api/appointment/connect
   * @secure
   */
  appointmentControllerCheckConsentAndCreateConnectionWithToken = (
    data: CreateConnectionDto,
    params: RequestParams = {},
  ) =>
    this.request<AnonymousOpenTokConnectionDTO, any>({
      path: `/api/appointment/connect`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerGetAppointment
   * @request GET:/api/appointment/{id}
   * @secure
   */
  appointmentControllerGetAppointment = (id: string, params: RequestParams = {}) =>
    this.request<AppointmentDto, any>({
      path: `/api/appointment/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerDeleteAppointment
   * @request DELETE:/api/appointment/{id}
   * @secure
   */
  appointmentControllerDeleteAppointment = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/appointment/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerVoidAppointment
   * @request POST:/api/appointment/{id}/void
   * @secure
   */
  appointmentControllerVoidAppointment = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/appointment/${id}/void`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerCreateConnection
   * @request POST:/api/appointment/{id}/connect
   * @secure
   */
  appointmentControllerCreateConnection = (id: string, data: CreateConnectionDto, params: RequestParams = {}) =>
    this.request<OpenTokConnectionDto, any>({
      path: `/api/appointment/${id}/connect`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentControllerGenerateAppointmentTokenUrl
   * @request POST:/api/appointment/{id}/generate-token-url
   * @secure
   */
  appointmentControllerGenerateAppointmentTokenUrl = (id: string, params: RequestParams = {}) =>
    this.request<AppointmentTokenUrl, any>({
      path: `/api/appointment/${id}/generate-token-url`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExercises
   * @request GET:/api/exercise
   */
  exerciseControllerGetExercises = (params: RequestParams = {}) =>
    this.request<ExerciseDto[], any>({
      path: `/api/exercise`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseScores
   * @request GET:/api/exercise-score
   */
  exerciseControllerGetExerciseScores = (params: RequestParams = {}) =>
    this.request<ExerciseScoreDto[], any>({
      path: `/api/exercise-score`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseFlexibilities
   * @request GET:/api/exercise-flexibility
   */
  exerciseControllerGetExerciseFlexibilities = (params: RequestParams = {}) =>
    this.request<ExerciseFlexibilityDto[], any>({
      path: `/api/exercise-flexibility`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseGroup
   * @request GET:/api/exercise-group
   */
  exerciseControllerGetExerciseGroup = (params: RequestParams = {}) =>
    this.request<ExerciseDto[], any>({
      path: `/api/exercise-group`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseJointTrace
   * @request GET:/api/exercise-joint-trace/{exercise_id}
   */
  exerciseControllerGetExerciseJointTrace = (exerciseId: string, params: RequestParams = {}) =>
    this.request<ExerciseJointTraceDto, any>({
      path: `/api/exercise-joint-trace/${exerciseId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseMaxRomDistribution
   * @request GET:/api/exercise-rom-distribution/{exercise_id}
   */
  exerciseControllerGetExerciseMaxRomDistribution = (exerciseId: string, params: RequestParams = {}) =>
    this.request<ExerciseMaxRomDistributionDto, any>({
      path: `/api/exercise-rom-distribution/${exerciseId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseMaxRomDistributions
   * @request GET:/api/exercise-rom-distributions
   */
  exerciseControllerGetExerciseMaxRomDistributions = (
    query: {
      exercise_ids: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ExerciseMaxRomDistributionDto[], any>({
      path: `/api/exercise-rom-distributions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseJointOfInterest
   * @request GET:/api/exercise-joint-of-interest/{exercise_id}
   */
  exerciseControllerGetExerciseJointOfInterest = (exerciseId: string, params: RequestParams = {}) =>
    this.request<ExerciseJointOfInterestDto, any>({
      path: `/api/exercise-joint-of-interest/${exerciseId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseControllerGetExerciseJointsOfInterest
   * @request GET:/api/exercise-joints-of-interest
   */
  exerciseControllerGetExerciseJointsOfInterest = (
    query: {
      exercise_ids: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ExerciseJointOfInterestDto[], any>({
      path: `/api/exercise-joints-of-interest`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FrequencyControllerListExerciseFrequency
   * @request GET:/api/exercise-frequency
   */
  frequencyControllerListExerciseFrequency = (params: RequestParams = {}) =>
    this.request<FrequencyDto[], any>({
      path: `/api/exercise-frequency`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FrequencyControllerGetPatientExerciseFrequency
   * @request GET:/api/patient-exercise-frequency
   * @secure
   */
  frequencyControllerGetPatientExerciseFrequency = (
    query: {
      patient: string;
      excludedAppointment: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<FrequencyDateDto[], any>({
      path: `/api/patient-exercise-frequency`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerListPrescriptions
   * @request GET:/api/prescription
   * @secure
   */
  prescriptionControllerListPrescriptions = (
    query?: {
      patient?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<PrescriptionDto[], any>({
      path: `/api/prescription`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerUpdatePrescription
   * @request POST:/api/prescription
   * @secure
   */
  prescriptionControllerUpdatePrescription = (
    query: {
      patient: string;
    },
    data: PrescriptionDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prescription`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerGetPrescription
   * @request GET:/api/prescription/{id}
   * @secure
   */
  prescriptionControllerGetPrescription = (id: string, params: RequestParams = {}) =>
    this.request<PrescriptionDto, any>({
      path: `/api/prescription/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerDeletePrescription
   * @request DELETE:/api/prescription/{id}
   * @secure
   */
  prescriptionControllerDeletePrescription = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/prescription/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerSendHepEmail
   * @request POST:/api/prescription/{prescriptionId}/email
   * @secure
   */
  prescriptionControllerSendHepEmail = (prescriptionId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/prescription/${prescriptionId}/email`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerListPrescriptionTemplates
   * @request GET:/api/prescription-template
   * @secure
   */
  prescriptionControllerListPrescriptionTemplates = (
    query?: {
      clinician?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<PrescriptionTemplateDto[], any>({
      path: `/api/prescription-template`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerUpdatePrescriptionTemplate
   * @request POST:/api/prescription-template
   * @secure
   */
  prescriptionControllerUpdatePrescriptionTemplate = (
    query: {
      clinician: string;
    },
    data: PrescriptionTemplateDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prescription-template`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name PrescriptionControllerDeletePrescriptionTemplate
   * @request DELETE:/api/prescription-template/{id}
   * @secure
   */
  prescriptionControllerDeletePrescriptionTemplate = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/prescription-template/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name AssessmentGroupControllerGetAssessmentGroups
   * @request GET:/api/assessment-group
   * @secure
   */
  assessmentGroupControllerGetAssessmentGroups = (
    query: {
      clinician: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AssessmentGroupDto[], any>({
      path: `/api/assessment-group`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AssessmentGroupControllerUpdateAssessmentGroup
   * @request POST:/api/assessment-group
   * @secure
   */
  assessmentGroupControllerUpdateAssessmentGroup = (
    query: {
      clinician: string;
    },
    data: AssessmentGroupDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/assessment-group`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name AssessmentGroupControllerDeleteAssessmentGroup
   * @request DELETE:/api/assessment-group/{id}
   * @secure
   */
  assessmentGroupControllerDeleteAssessmentGroup = (
    id: string,
    query: {
      clinician: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/assessment-group/${id}`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name CalendlyControllerCalendlyWebhook
   * @request POST:/api/calendly/webhook
   */
  calendlyControllerCalendlyWebhook = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/calendly/webhook`,
      method: 'POST',
      ...params,
    });
  /**
   * No description
   *
   * @name CalendlyControllerTestSyncCalendly
   * @request GET:/api/calendly/sync
   * @secure
   */
  calendlyControllerTestSyncCalendly = (params: RequestParams = {}) =>
    this.request<CalendlyAction[], any>({
      path: `/api/calendly/sync`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CalendlyControllerDoSyncCalendly
   * @request POST:/api/calendly/sync
   * @secure
   */
  calendlyControllerDoSyncCalendly = (
    query?: {
      event?: string;
      user?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<CalendlyAction[], any>({
      path: `/api/calendly/sync`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name KeypointControllerListKeypoints
   * @request GET:/api/keypoints
   * @secure
   */
  keypointControllerListKeypoints = (
    query: {
      patient: string;
      start: string;
      end: string;
      prescription?: string;
      appointment?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<KeypointSnapshotDto[], any>({
      path: `/api/keypoints`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name KeypointControllerSetKeypoints
   * @request POST:/api/keypoints
   * @secure
   */
  keypointControllerSetKeypoints = (
    query: {
      patient: string;
      start: string;
      appointment?: string;
      prescription?: string;
    },
    data: KeypointSnapshotDto[],
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/keypoints`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name KeypointControllerGetSessionKeypointsByExerciseId
   * @request GET:/api/keypoints/{patientId}/{appointmentId}/{exerciseId}
   * @secure
   */
  keypointControllerGetSessionKeypointsByExerciseId = (
    patientId: string,
    appointmentId: string,
    exerciseId: string,
    params: RequestParams = {},
  ) =>
    this.request<ExerciseKeypoints, any>({
      path: `/api/keypoints/${patientId}/${appointmentId}/${exerciseId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name SearchControllerGetSearchLogs
   * @request GET:/api/search-log/{appointmentId}
   * @secure
   */
  searchControllerGetSearchLogs = (
    appointmentId: string,
    query: {
      clinician: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<SearchSummaryDto, any>({
      path: `/api/search-log/${appointmentId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name SearchControllerUpdateSearchLogs
   * @request POST:/api/search-log
   * @secure
   */
  searchControllerUpdateSearchLogs = (data: SearchSummaryDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/search-log`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name SummaryControllerGetSessionSummaries
   * @request GET:/api/session-summary
   * @secure
   */
  summaryControllerGetSessionSummaries = (
    query: {
      appointment: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<SessionSummaryDto, any>({
      path: `/api/session-summary`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name SummaryControllerUpdateSessionSummary
   * @request POST:/api/session-summary
   * @secure
   */
  summaryControllerUpdateSessionSummary = (data: SessionSummaryDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/session-summary`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name SummaryControllerUpdateExerciseStats
   * @request POST:/api/session-summary/exercise-stats/{patientId}/{appointmentId}
   * @secure
   */
  summaryControllerUpdateExerciseStats = (
    patientId: string,
    appointmentId: string,
    data: ExerciseStats,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/session-summary/exercise-stats/${patientId}/${appointmentId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name SummaryControllerVoidExercise
   * @request POST:/api/session-summary/void-exercise/{exerciseStatsId}
   * @secure
   */
  summaryControllerVoidExercise = (exerciseStatsId: string, data: SessionSummaryDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/session-summary/void-exercise/${exerciseStatsId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerGetAppointmentScreenshots
   * @request GET:/api/screenshots
   * @secure
   */
  screenshotControllerGetAppointmentScreenshots = (
    query: {
      patient: string;
      appointment: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AppointmentScreenshotDto, any>({
      path: `/api/screenshots`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerDeleteScreenshot
   * @request DELETE:/api/screenshots
   * @secure
   */
  screenshotControllerDeleteScreenshot = (data: ScreenshotUploadDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/screenshots`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerGetPatientScreenshots
   * @request GET:/api/screenshots/patient/{patientId}
   * @secure
   */
  screenshotControllerGetPatientScreenshots = (patientId: string, params: RequestParams = {}) =>
    this.request<AppointmentScreenshotDto[], any>({
      path: `/api/screenshots/patient/${patientId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerUpdateScreenshots
   * @request POST:/api/screenshots/patient/{patientId}
   * @secure
   */
  screenshotControllerUpdateScreenshots = (
    patientId: string,
    data: AppointmentScreenshotDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/screenshots/patient/${patientId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerGetScreenshotsUrlByPatientAndAppointment
   * @request GET:/api/screenshots/url
   * @secure
   */
  screenshotControllerGetScreenshotsUrlByPatientAndAppointment = (
    query: {
      patientId: string;
      appointmentId: string;
      exerciseId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ExerciseScreenshotDto, any>({
      path: `/api/screenshots/url`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerEditScreenshot
   * @request POST:/api/screenshots/patient/{patientId}/{appointmentId}
   * @secure
   */
  screenshotControllerEditScreenshot = (
    patientId: string,
    appointmentId: string,
    data: ScreenshotItem,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/screenshots/patient/${patientId}/${appointmentId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerUseBaseImage
   * @request POST:/api/screenshots/patient/base/{patientId}/{appointmentId}
   * @secure
   */
  screenshotControllerUseBaseImage = (
    patientId: string,
    appointmentId: string,
    data: ScreenshotItem,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/screenshots/patient/base/${patientId}/${appointmentId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerUploadFile
   * @request POST:/api/screenshots/upload
   * @secure
   */
  screenshotControllerUploadFile = (
    data: {
      uploadPath?: string;
      /** @format binary */
      file?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/screenshots/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerGetScreenshotUrl
   * @request GET:/api/screenshot/patient/{patientId}/access
   * @secure
   */
  screenshotControllerGetScreenshotUrl = (
    patientId: string,
    query: {
      uploadPath: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ScreenshotUrlDto, any>({
      path: `/api/screenshot/patient/${patientId}/access`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ScreenshotControllerGetScreenshotUrls
   * @request POST:/api/screenshots/patient/{patientId}/access
   * @secure
   */
  screenshotControllerGetScreenshotUrls = (patientId: string, data: string[], params: RequestParams = {}) =>
    this.request<ScreenshotUrlDto[], any>({
      path: `/api/screenshots/patient/${patientId}/access`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name NoteControllerGetNote
   * @request GET:/api/notes
   * @secure
   */
  noteControllerGetNote = (
    query: {
      patient: string;
      appointment: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<NoteDto, any>({
      path: `/api/notes`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name NoteControllerGetPatientNotes
   * @request GET:/api/notes/patient/{patientId}
   * @secure
   */
  noteControllerGetPatientNotes = (patientId: string, params: RequestParams = {}) =>
    this.request<NoteDto[], any>({
      path: `/api/notes/patient/${patientId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name NoteControllerUpdateNote
   * @request POST:/api/notes/patient/{patientId}
   * @secure
   */
  noteControllerUpdateNote = (patientId: string, data: NoteDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/notes/patient/${patientId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FormControllerCreateAppointmentForm
   * @request POST:/api/form/{type}
   * @secure
   */
  formControllerCreateAppointmentForm = (
    type: 'Intake' | 'FollowUp' | 'Consent',
    data: AppointmentDto,
    params: RequestParams = {},
  ) =>
    this.request<FormDto, any>({
      path: `/api/form/${type}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FormControllerListForms
   * @request GET:/api/form
   * @secure
   */
  formControllerListForms = (
    query?: {
      patient?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<FormDto[], any>({
      path: `/api/form`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FormControllerUpdateForm
   * @request POST:/api/form
   * @secure
   */
  formControllerUpdateForm = (data: FormDto, params: RequestParams = {}) =>
    this.request<FormDto, any>({
      path: `/api/form`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FormControllerGetForm
   * @request GET:/api/form/{id}
   * @secure
   */
  formControllerGetForm = (id: string, params: RequestParams = {}) =>
    this.request<FormDto, any>({
      path: `/api/form/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FormControllerDeleteForm
   * @request DELETE:/api/form/{id}
   * @secure
   */
  formControllerDeleteForm = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/form/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @name FormControllerGetFormForAppointment
   * @request GET:/api/form/appointment/{appointmentId}
   * @secure
   */
  formControllerGetFormForAppointment = (appointmentId: string, params: RequestParams = {}) =>
    this.request<FormDto, any>({
      path: `/api/form/appointment/${appointmentId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentReportControllerSendAppointmentReportEmail
   * @request POST:/api/report/email/{appointmentId}
   * @secure
   */
  appointmentReportControllerSendAppointmentReportEmail = (appointmentId: string, params: RequestParams = {}) =>
    this.request<EmailDto, any>({
      path: `/api/report/email/${appointmentId}`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentReportControllerGetAppointmentReport
   * @request GET:/api/report/{appointmentId}
   * @secure
   */
  appointmentReportControllerGetAppointmentReport = (appointmentId: string, params: RequestParams = {}) =>
    this.request<AppointmentReportDto, any>({
      path: `/api/report/${appointmentId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentReportControllerGetAppointmentReportCsv
   * @request GET:/api/report/{appointmentId}/csv
   * @secure
   */
  appointmentReportControllerGetAppointmentReportCsv = (appointmentId: string, params: RequestParams = {}) =>
    this.request<AppointmentReportCsv[], any>({
      path: `/api/report/${appointmentId}/csv`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AppointmentRomReportControllerGetAppointmentReport
   * @request GET:/api/appointment-rom/{appointmentId}/{movementIndex}
   * @secure
   */
  appointmentRomReportControllerGetAppointmentReport = (
    appointmentId: string,
    movementIndex: string,
    params: RequestParams = {},
  ) =>
    this.request<AppointmentRomReportDto, any>({
      path: `/api/appointment-rom/${appointmentId}/${movementIndex}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ReportScreenshotControllerGetReportScreenshots
   * @request GET:/api/report/screenshots
   * @secure
   */
  reportScreenshotControllerGetReportScreenshots = (
    query: {
      patientId: string;
      appointment: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ReportScreenshotDto, any>({
      path: `/api/report/screenshots`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ReportScreenshotControllerGetPatientReportScreenshots
   * @request GET:/api/report/screenshots/{patientId}
   * @secure
   */
  reportScreenshotControllerGetPatientReportScreenshots = (patientId: string, params: RequestParams = {}) =>
    this.request<ReportScreenshotDto[], any>({
      path: `/api/report/screenshots/${patientId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ReportScreenshotControllerUpdateReportScreenshots
   * @request POST:/api/report/screenshots/{patientId}
   * @secure
   */
  reportScreenshotControllerUpdateReportScreenshots = (
    patientId: string,
    data: ReportScreenshotDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/report/screenshots/${patientId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name TeamControllerGetTeam
   * @request GET:/api/team/{id}
   * @secure
   */
  teamControllerGetTeam = (id: string, params: RequestParams = {}) =>
    this.request<TeamDto, any>({
      path: `/api/team/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name TeamControllerGetTeamsForClinician
   * @request GET:/api/team
   * @secure
   */
  teamControllerGetTeamsForClinician = (
    query: {
      clinicianId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<TeamDto[], any>({
      path: `/api/team`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name TeamControllerUpdateTeam
   * @request POST:/api/team
   * @secure
   */
  teamControllerUpdateTeam = (params: RequestParams = {}) =>
    this.request<TeamDto, any>({
      path: `/api/team`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerGetOrRequestConsentFormatted
   * @request POST:/api/consentFormatted
   * @secure
   */
  consentControllerGetOrRequestConsentFormatted = (data: ConsentRequestDto, params: RequestParams = {}) =>
    this.request<ConsentDto, any>({
      path: `/api/consentFormatted`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerSendConsentEmail
   * @request POST:/api/consent/email
   * @secure
   */
  consentControllerSendConsentEmail = (data: ConsentRequestDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/consent/email`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerGetOrRequestConsent
   * @request POST:/api/consent
   * @deprecated
   * @secure
   */
  consentControllerGetOrRequestConsent = (
    query: {
      patientEmail: string;
      teamId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ConsentDto, any>({
      path: `/api/consent`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerCheckConsent
   * @request GET:/api/consent
   * @secure
   */
  consentControllerCheckConsent = (
    query: {
      patientId: string;
      teamId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<CheckConsentDto, any>({
      path: `/api/consent`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerCheckClinicianHasConsent
   * @request GET:/api/consent/clinician
   * @secure
   */
  consentControllerCheckClinicianHasConsent = (
    query: {
      patientId: string;
      clinicianId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ConsentDto, any>({
      path: `/api/consent/clinician`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerGetConsentForm
   * @request GET:/api/consent/form/{formId}
   * @secure
   */
  consentControllerGetConsentForm = (formId: string, params: RequestParams = {}) =>
    this.request<FormDto, any>({
      path: `/api/consent/form/${formId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerGetConsentFormWithToken
   * @request GET:/api/consent/token
   * @secure
   */
  consentControllerGetConsentFormWithToken = (params: RequestParams = {}) =>
    this.request<FormDto, any>({
      path: `/api/consent/token`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerApproveConsent
   * @request POST:/api/consent-approve
   * @secure
   */
  consentControllerApproveConsent = (data: FormDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/consent-approve`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ConsentControllerRevokeConsent
   * @request POST:/api/consent-revoke/{formId}
   * @secure
   */
  consentControllerRevokeConsent = (formId: string, params: RequestParams = {}) =>
    this.request<ConsentDto, any>({
      path: `/api/consent-revoke/${formId}`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseStatControllerGetExerciseStat
   * @request GET:/api/stats/{exerciseStatId}
   * @secure
   */
  exerciseStatControllerGetExerciseStat = (exerciseStatId: string, params: RequestParams = {}) =>
    this.request<ExerciseStats, any>({
      path: `/api/stats/${exerciseStatId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseStatControllerGetPatientExerciseStats
   * @request GET:/api/stats
   * @secure
   */
  exerciseStatControllerGetPatientExerciseStats = (
    query: {
      patientId: string;
      startDate?: string;
      endDate?: string;
      source?: string;
      prescriptionId?: string;
      ascBy?: string;
      decBy?: string;
      limit?: string;
      startAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ExerciseStats[], any>({
      path: `/api/stats`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseStatControllerGetExerciseStatsBySession
   * @request GET:/api/session-stats
   * @secure
   */
  exerciseStatControllerGetExerciseStatsBySession = (
    query: {
      patientId: string;
      source?: string;
      prescriptionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<SessionExerciseStats[], any>({
      path: `/api/session-stats`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseStatControllerUpdateExerciseStat
   * @request POST:/api/stats/patient/{patientId}
   * @secure
   */
  exerciseStatControllerUpdateExerciseStat = (patientId: string, data: ExerciseStats, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/stats/patient/${patientId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ExerciseStatControllerSaveSelfTestStats
   * @request POST:/api/stats/self-test
   */
  exerciseStatControllerSaveSelfTestStats = (data: SaveSelfTestStatsDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/stats/self-test`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ReportControllerGetHomeExerciseReport
   * @request GET:/api/reports/hep
   * @secure
   */
  reportControllerGetHomeExerciseReport = (
    query: {
      patientId: string;
      startDate: string;
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<HEPReportDto, any>({
      path: `/api/reports/hep`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ReportControllerGetActivityReport
   * @request GET:/api/reports/activity
   * @secure
   */
  reportControllerGetActivityReport = (
    query: {
      patientId: string;
      clinicianId: string;
      startDate: string;
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ActivityReportDto, any>({
      path: `/api/reports/activity`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name SendGridControllerSendGridWebhook
   * @request POST:/api/sendgrid/webhook
   */
  sendGridControllerSendGridWebhook = (data: string[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/sendgrid/webhook`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name EpisodeControllerGetAllBillableCodes
   * @request GET:/api/episodes/codes
   * @secure
   */
  episodeControllerGetAllBillableCodes = (params: RequestParams = {}) =>
    this.request<EpisodeWithBillableCodeDto[], any>({
      path: `/api/episodes/codes`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name EpisodeControllerUpdateBillableCode
   * @request PUT:/api/episode/{episodeId}/code/{codeId}
   * @secure
   */
  episodeControllerUpdateBillableCode = (
    episodeId: string,
    codeId: string,
    data: BillableCodeUpdateReq,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/episode/${episodeId}/code/${codeId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name EpisodeControllerDownloadReport
   * @request GET:/api/episode/{episodeId}/code/{codeId}
   * @secure
   */
  episodeControllerDownloadReport = (episodeId: string, codeId: string, params: RequestParams = {}) =>
    this.request<DownloadReportDto, any>({
      path: `/api/episode/${episodeId}/code/${codeId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name EpisodeControllerGetEpisodes
   * @request GET:/api/episodes
   * @secure
   */
  episodeControllerGetEpisodes = (params: RequestParams = {}) =>
    this.request<PatientEpisodesDto[], any>({
      path: `/api/episodes`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name EpisodeControllerStartNewEpisode
   * @request POST:/api/episode
   * @secure
   */
  episodeControllerStartNewEpisode = (data: EpisodeOpenDto, params: RequestParams = {}) =>
    this.request<EpisodeDto, any>({
      path: `/api/episode`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name EpisodeControllerUpdateEpisode
   * @request POST:/api/episode/{episodeId}
   * @secure
   */
  episodeControllerUpdateEpisode = (episodeId: string, data: EpisodeUpdateDto, params: RequestParams = {}) =>
    this.request<EpisodeDto, any>({
      path: `/api/episode/${episodeId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name MessageControllerSendMessageToPatient
   * @request POST:/api/message/clinician/send
   * @secure
   */
  messageControllerSendMessageToPatient = (data: CreateMessageDto, params: RequestParams = {}) =>
    this.request<MessageDto, any>({
      path: `/api/message/clinician/send`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name MessageControllerSendMessageToClinician
   * @request POST:/api/message/patient/send
   * @secure
   */
  messageControllerSendMessageToClinician = (data: CreateMessageDto, params: RequestParams = {}) =>
    this.request<MessageDto, any>({
      path: `/api/message/patient/send`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name MessageControllerGetMessagesFromPatient
   * @request GET:/api/message/clinician
   * @secure
   */
  messageControllerGetMessagesFromPatient = (
    query: {
      interlocutorId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<MessageDto[], any>({
      path: `/api/message/clinician`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name MessageControllerGetMessagesFromClinician
   * @request GET:/api/message/patient
   * @secure
   */
  messageControllerGetMessagesFromClinician = (
    query: {
      interlocutorId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<MessageDto[], any>({
      path: `/api/message/patient`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name MessageControllerListInterlocutors
   * @request GET:/api/message/interlocutors
   */
  messageControllerListInterlocutors = (params: RequestParams = {}) =>
    this.request<ClinicianDto[], any>({
      path: `/api/message/interlocutors`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ContactControllerCreateContact
   * @request POST:/api/contact/clinician
   * @secure
   */
  contactControllerCreateContact = (data: CreateContactDto, params: RequestParams = {}) =>
    this.request<ContactDto, any>({
      path: `/api/contact/clinician`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name TasksControllerSyncRtm
   * @request GET:/api/tasks/rtm/sync
   */
  tasksControllerSyncRtm = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/tasks/rtm/sync`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @name TasksControllerTestRoute
   * @request GET:/api/tasks/test
   */
  tasksControllerTestRoute = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/tasks/test`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name GeneralTestControllerListGeneralTests
   * @request GET:/api/general-test
   */
  generalTestControllerListGeneralTests = (params: RequestParams = {}) =>
    this.request<GeneralTestDto[], any>({
      path: `/api/general-test`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name AthenaControllerGetAthenaToken
   * @request POST:/api/athena/auth/{clinicianId}
   * @secure
   */
  athenaControllerGetAthenaToken = (clinicianId: string, params: RequestParams = {}) =>
    this.request<AthenaTokenResponse, any>({
      path: `/api/athena/auth/${clinicianId}`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CapabilityControllerGetCapabilities
   * @request GET:/api/capability/{clinicianId}
   * @secure
   */
  capabilityControllerGetCapabilities = (clinicianId: string, params: RequestParams = {}) =>
    this.request<CapabilityReport[], any>({
      path: `/api/capability/${clinicianId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name TransactionControllerCreateTransactionFromReceipt
   * @request POST:/api/receipt/{teamId}
   * @secure
   */
  transactionControllerCreateTransactionFromReceipt = (teamId: string, data: ReceiptDto, params: RequestParams = {}) =>
    this.request<PurchaseDto, any>({
      path: `/api/receipt/${teamId}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name TriageLogControllerLogTriageTimestamp
   * @request POST:/api/triage/log
   */
  triageLogControllerLogTriageTimestamp = (data: CreateTriageLogDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/triage/log`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
