import { URLSearchParams } from 'url';
import {
  ExerciseStats,
  PrescriptionDto,
} from '../../api/backend-client/data-contracts';
import {
  KNEE_TRIAGE_ASSESSMENT,
  HIP_TRIAGE_ASSESSMENT,
  LOW_BACK_TRIAGE_ASSESSMENT,
  SHOULDER_TRIAGE_ASSESSMENT,
} from './constants';

export enum ShoulderRisk {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
}

export const getTriageInstructionVideo = (type: string | null): string => {
  switch (type) {
    case '1':
      return '/video/Low_Back_Triage_Instructions.mp4';
    case '2':
      return '/video/Shoulder_Triage_Instructions.mp4';
    case '3':
      return '/video/Knee_Triage_Instructions.mp4';
    case '4':
      return '/video/Hip_Triage_Instructions.mp4';
    default:
      return '/video/SelfTestSetupInstruction.mp4';
  }
};

export const getTriageAssessment = (type: string | null): PrescriptionDto => {
  switch (type) {
    case '1':
      return LOW_BACK_TRIAGE_ASSESSMENT;
    case '2':
      return SHOULDER_TRIAGE_ASSESSMENT;
    case '3':
      return KNEE_TRIAGE_ASSESSMENT;
    case '4':
      return HIP_TRIAGE_ASSESSMENT;
    default:
      return LOW_BACK_TRIAGE_ASSESSMENT;
  }
};

export const getTriageRecommendation = (
  type: string | null,
): { recommendationTitle: string; recommendationText: string } => {
  switch (type) {
    case '1':
      return {
        recommendationTitle: 'Virtual Physical Therapy Evaluation',
        recommendationText: 'a Physical Therapist',
      };
    case '2':
      return {
        recommendationTitle: 'Orthopedic Surgeon Evaluation',
        recommendationText: 'an Orthopedic Surgeon',
      };
    case '3':
      return {
        recommendationTitle: 'In-Person Physical Therapy Evaluation',
        recommendationText: 'a Physical Therapist',
      };
    default:
      return {
        recommendationTitle: 'Virtual Physical Therapy Evaluation',
        recommendationText: 'a Physical Therapist',
      };
  }
};

export const getTriageNumMovements = (type: string | null): number => {
  return getTriageAssessment(type).configurable_exercise.length;
};

export const buildTriageRecommendationUrl = (
  type: string | null,
  searchParams: URLSearchParams,
  stats: ExerciseStats[],
): string => {
  switch (type) {
    case '1':
      return `/triage/recommendation?${searchParams}&duration=${extract5STSDuration(
        stats,
      )}`;
    case '2':
      return `/triage/recommendation?${searchParams}&duration=${extract5STSDuration(
        stats,
      )}&${scoreShoulderAssessment(stats)}`;
    case '3':
      return `/triage/recommendation?${searchParams}&duration=${extract5STSDuration(
        stats,
      )}&${scoreKneeAssessment(stats)}`;
    case '4':
      return `/triage/recommendation?${searchParams}&duration=${extract5STSDuration(
        stats,
      )}&${scoreHipAssessment(stats)}`;
    default:
      return `/triage/recommendation?${searchParams}&duration=${extract5STSDuration(
        stats,
      )}`;
  }
};

function extract5STSDuration(stats: ExerciseStats[]): string {
  return (
    stats
      .find((stat) => stat.exercise_id === 'EX-00064')
      ?.duration?.toFixed(2) ?? '0'
  );
}

function scoreShoulderAssessment(stats: ExerciseStats[]): string {
  const flexion = stats.find((stat) => stat.exercise_id === 'EX-00038');
  const abduction = stats.find((stat) => stat.exercise_id === 'EX-00033');

  const flexionRom =
    flexion?.max_ROM || flexion?.left_max_ROM || flexion?.right_max_ROM || 180;
  const abductionRom =
    abduction?.max_ROM ||
    abduction?.left_max_ROM ||
    abduction?.right_max_ROM ||
    180;

  let flexionScore = ShoulderRisk.High;
  let abductionScore = ShoulderRisk.High;

  if (flexionRom < 95) {
    flexionScore = ShoulderRisk.Low;
  } else if (flexionRom <= 150) {
    flexionScore = ShoulderRisk.Moderate;
  } else {
    flexionScore = ShoulderRisk.High;
  }

  if (abductionRom < 95) {
    abductionScore = ShoulderRisk.Low;
  } else if (abductionRom <= 150) {
    abductionScore = ShoulderRisk.Moderate;
  } else {
    abductionScore = ShoulderRisk.High;
  }

  let riskScore = 0;
  if (flexionScore === ShoulderRisk.Low) {
    riskScore += 1;
  }

  if (abductionScore === ShoulderRisk.Low) {
    riskScore += 1;
  }

  return `shoulderRisk=${riskScore}`;
}

function scoreKneeAssessment(stats: ExerciseStats[]): string {
  const heelSlide = stats.find((stat) => stat.exercise_id === 'EX-00090');
  const seatedKneeExtension = stats.find(
    (stat) => stat.exercise_id === 'EX-00051',
  );

  const heelSlideRom =
    heelSlide?.max_ROM ||
    heelSlide?.left_max_ROM ||
    heelSlide?.right_max_ROM ||
    0;
  const seatedKneeExtensionRom =
    seatedKneeExtension?.max_ROM ||
    seatedKneeExtension?.left_max_ROM ||
    seatedKneeExtension?.right_max_ROM ||
    0;
  let riskScore = 0;

  if (heelSlideRom < 110) {
    riskScore += 1;
  }

  if (seatedKneeExtensionRom < 165) {
    riskScore += 1;
  }

  return `kneeRisk=${riskScore}`;
}

function scoreHipAssessment(stats: ExerciseStats[]): string {
  const sidelyingHipInternalRotation = stats.find(
    (stat) => stat.exercise_id === 'EX-00145',
  );

  const rom =
    sidelyingHipInternalRotation?.max_ROM ||
    sidelyingHipInternalRotation?.left_max_ROM ||
    sidelyingHipInternalRotation?.right_max_ROM ||
    90;
  let riskScore = 0;
  if (rom < 20) {
    riskScore += 1;
  }
  return `hipRisk=${riskScore}`;
}

export enum FallRisk {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
}

export enum FrailtyRisk {
  NotFrail = 'not-frail',
  PreFrail = 'pre-frail',
  Frail = 'frail',
}

export enum SarcopeniaRisk {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
}

export function assessFallRisk(
  hasFallen: string | null,
  fallScore: string | null,
  duration5TSTS: number,
): FallRisk {
  if (duration5TSTS === 0) {
    if (fallScore === null) {
      return FallRisk.Moderate;
    }
    const score = parseFloat(fallScore);
    if (score <= 2) {
      return FallRisk.Low;
    } else if (score > 2 && score <= 4) {
      return FallRisk.Moderate;
    } else {
      return FallRisk.High;
    }
  }

  if (hasFallen === 't') {
    if (duration5TSTS <= 15.4) {
      return FallRisk.Moderate;
    } else {
      return FallRisk.High;
    }
  } else if (hasFallen === 'f') {
    if (duration5TSTS <= 15.4) {
      return FallRisk.Low;
    } else {
      return FallRisk.Moderate;
    }
  }

  return FallRisk.Moderate;
}

export function assessFrailtyRisk(
  sex: string | null,
  frailty: string | null,
  slower: string | null,
  duration5TSTS: number,
) {
  if (frailty === null || sex === null) {
    return FrailtyRisk.PreFrail;
  }

  let frailtyScore = parseFloat(frailty);

  if (duration5TSTS === 0) {
    if (slower === 't') {
      frailtyScore += 1;
    }

    if (frailtyScore < 1) {
      return FrailtyRisk.NotFrail;
    } else if (frailtyScore >= 1 && frailtyScore <= 2) {
      return FrailtyRisk.PreFrail;
    } else {
      return FrailtyRisk.Frail;
    }
  }

  if (sex === 'm') {
    if (duration5TSTS >= 14.15) {
      frailtyScore += 1;
    }
  }

  if (sex === 'f') {
    if (duration5TSTS >= 14.67) {
      frailtyScore += 1;
    }
  }

  if (frailtyScore < 1) {
    return FrailtyRisk.NotFrail;
  } else if (frailtyScore >= 1 && frailtyScore <= 2) {
    return FrailtyRisk.PreFrail;
  } else {
    return FrailtyRisk.Frail;
  }
}

export function assessSarcopeniaRisk(duration5TSTS: number): SarcopeniaRisk {
  if (duration5TSTS < 11) {
    return SarcopeniaRisk.Low;
  } else if (duration5TSTS >= 11 && duration5TSTS < 13) {
    return SarcopeniaRisk.Moderate;
  } else {
    return SarcopeniaRisk.High;
  }
}
