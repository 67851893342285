import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import theme from '../../theme';

const joyrideState = {
  run: false,
  sessionStepIndex: 0,
  sessionSteps: [
    {
      target: '#sessions-list',
      content: 'Your sessions will be listed here',
      disableBeacon: true,
    },
    {
      target: '#view-detailed-report-btn',
      content: 'Click here to view detailed report',
      disableBeacon: true,
    },
    {
      target: '#history-tabs',
      content: 'Select a tab to view a detailed report of your session',
      disableBeacon: true,
    },
  ],
  hepStepIndex: 0,
  hepSteps: [
    {
      target: '#hep-drawer',
      content: 'Details of your Home Exercise Plan will be displayed here',
      disableBeacon: true,
    },
    {
      target: '#stats-overlay',
      content: 'Repetitions, Sets, and Duration will be displayed here',
      disableBeacon: true,
    },
    {
      target: '#action-button-overlay',
      content:
        'Exit, Settings, Pause, Resume, and Skip control buttons are available here',
      disableBeacon: true,
    },
  ],
};

export const joyrideStyles = {
  options: {
    zIndex: 10000,
    overlayColor: 'rgba(0, 0, 0, 0.7)',
    primaryColor: theme.palette.primary.main,
  },
};

export const JoyrideContext = createContext({
  state: joyrideState,
  setState: (state: typeof joyrideState) => {},
});

export const JoyrideProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState(joyrideState);
  const value = useMemo(() => ({ state, setState }), [state, setState]);

  return (
    <JoyrideContext.Provider value={value}>{children}</JoyrideContext.Provider>
  );
};

export const useJoyrideContext = () => {
  const context = useContext(JoyrideContext);

  return context;
};
